import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const CSShadowPalaceSpectralPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Spectral</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_spectral.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Spectral</h1>
          <h2>What kind of gear you can get from the Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="2-set options" />
        <p>Those sets can roll on R-SSR Spectral gear pieces.</p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_bullet.png"
          alt="Spectral Gear"
        />
        <h4>Spectral Bullet</h4>
        <ul>
          <li>ATK +10%</li>
          <li>HIT +10%</li>
        </ul>
        <p>
          Great set for all damage dealers. HIT is pretty important to counter
          employees with high evasion and Perfect Evasion.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_chain.png"
          alt="Spectral Gear"
        />
        <h4>Spectral Chain</h4>
        <ul>
          <li>ATK +10%</li>
          <li>CRIT +10%</li>
        </ul>
        <p>It's okay set if you want to go full offense.</p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_gear.png"
          alt="Spectral Gear"
        />
        <h4>Spectral Gear</h4>
        <ul>
          <li>ATK +10%</li>
          <li>EVA +10%</li>
        </ul>
        <p>Well, still better than the normal ATK set.</p>
        <SectionHeader title="4-set options" />
        <p>Those sets can roll only on SR and SSR Spectral gear pieces.</p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_smite.png"
          alt="Spectral Gear"
        />
        <h4>Spectral Smite</h4>
        <ul>
          <li>ATK +16%</li>
          <li>CRIT DMG +16%</li>
        </ul>
        <p>
          There aren't that many employees that make use of CRIT DMG and you
          usually want to use the normal CRIT DMG set on them, but it's still
          usable.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_blaze.png"
          alt="Spectral Gear"
        />
        <h4>Spectral Blaze</h4>
        <ul>
          <li>ATK +16%</li>
          <li>ASPD +8%</li>
        </ul>
        <p>
          Great for all employees that rely on Basic Attack to deal damage. BIS
          set for a lot of Soldiers and Mechs, but also some Counter like using
          it.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_spirit.png"
          alt="Spectral Gear"
        />
        <h4>Spectral Spirit</h4>
        <ul>
          <li>ATK +16%</li>
          <li>Skill Haste +12%</li>
        </ul>
        <p>Best on Counters as they want Skill Haste above everything.</p>
        <SectionHeader title="Substats" />
        <p>
          Below you will find a list of available rolls on the Spectral Gear.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/spectral_weapon.png"
          alt="Spectral Gear"
        />
        <h4>Weapon</h4>
        <p>Main stat:</p>
        <ul>
          <li>ATK</li>
        </ul>
        <p>Option 1 (prefix):</p>
        <ul>
          <li>Anti-Striker DMG</li>
          <li>Anti-Defender DMG</li>
          <li>Anti-Ranger DMG</li>
          <li>Anti-Sniper DMG</li>
        </ul>
        <p>Option 2 (suffix):</p>
        <ul>
          <li>ASPD</li>
          <li>SPD</li>
          <li>Crit DMG</li>
          <li>Anti-Ground DMG</li>
          <li>Anti-Air DMG</li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/spectral_armor.webp"
          alt="Spectral Gear"
        />
        <h4>Armor</h4>
        <p>Main stat:</p>
        <ul>
          <li>HP</li>
        </ul>
        <p>Option 1 (prefix):</p>
        <ul>
          <li>Anti-Striker DMG</li>
          <li>Anti-Defender DMG</li>
          <li>Anti-Ranger DMG</li>
          <li>Anti-Sniper DMG</li>
        </ul>
        <p>Option 2 (suffix):</p>
        <ul>
          <li>Skill Haste</li>
          <li>CC Res</li>
          <li>CRIT DMG Res</li>
          <li>Anti-Ground DMG Res</li>
          <li>Anti-Air DMG Res</li>
          <li>Anti-Striker DMG Res</li>
          <li>Anti-Defender DMG Res</li>
          <li>Anti-Ranger DMG Res</li>
          <li>Anti-Sniper DMG Res</li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/spectral_acc.webp"
          alt="Spectral Gear"
        />
        <h4>Accessory</h4>
        <p>Main stat:</p>
        <ul>
          <li>HIT</li>
        </ul>
        <p>Option 1 (prefix):</p>
        <ul>
          <li>Anti-Striker DMG</li>
          <li>Anti-Defender DMG</li>
          <li>Anti-Ranger DMG</li>
          <li>Anti-Sniper DMG</li>
        </ul>
        <p>Option 2 (suffix):</p>
        <ul>
          <li>Skill Haste</li>
          <li>CC Res</li>
          <li>CRIT DMG</li>
          <li>CRIT DMG Res</li>
          <li>Anti-Ground DMG</li>
          <li>Anti-Air DMG</li>
          <li>Anti-Air DMG Res</li>
          <li>Anti-Striker DMG Res</li>
          <li>Anti-Defender DMG Res</li>
          <li>Anti-Ranger DMG Res</li>
          <li>Anti-Sniper DMG Res</li>
        </ul>
        <p>
          As you can see from the available stats, Spectral sets are purely
          offensive type of gear - and the ability to combine Anti-X DMG with
          Anti-Ground DMG makes it perfect for characters that you use to
          counter specific threats. For example Anti-Defender DMG + Anti-Ground
          DMG Spectral Blaze set equipped on Administrator Rifleman will turn
          them into monsters that will melt Defenders even faster in the
          Gauntlet.
        </p>
        <p>
          Also, <strong>don't waste your Set Binaries on Spectral</strong>.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceSpectralPage;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace Spectral | Counter Side | Prydwen Institute"
    description="What kind of gear you can get from the Shadow Palace."
  />
);
